import {
  fetchAddBreederUploadedImageEndpoint,
  fetchAddDogUploadedImageEndpoint,
  fetchAddLitterPuppyImageEndpoint,
  fetchAddLitterUploadedImageEndpoint,
  fetchAddMemberUploadedImageEndpoint,
  fetchCreateMemberUploadAssetTokenEndpoint,
} from "./generated/happydogsComponents";

export const uploadMemberImage = async (memberId: string, file: File | Blob) => {
  const data = await fetchCreateMemberUploadAssetTokenEndpoint({
    body: {
      memberId,
      mimeType: file.type,
    },
  });
  await fetch(data.url, {
    method: "PUT",
    body: file,
    headers: data.headers,
  });

  await fetchAddMemberUploadedImageEndpoint({
    body: {
      mimeType: file.type,
      assetId: data.assetId,
      memberId: memberId,
      filename: "",
      setProfilePicture: true,
    },
  });

  return { url: data.url, assetId: data.assetId };
};

export const uploadDogImage = async (memberId: string, dogId: string, file: File | Blob, profilePicture: boolean) => {
  const data = await fetchCreateMemberUploadAssetTokenEndpoint({
    body: {
      memberId,
      mimeType: file.type,
    },
  });
  await fetch(data.url, {
    method: "PUT",
    body: file,
    headers: data.headers,
  });

  await fetchAddDogUploadedImageEndpoint({
    body: {
      mimeType: file.type,
      assetId: data.assetId,
      setProfilePicture: profilePicture,
      memberId,
      dogId: dogId,
      filename: "",
    },
  });

  return { url: data.url, assetId: data.assetId };
};

export const uploadBreederImage = async (memberId: string, breederId: string, file: File | Blob, profilePicture: boolean) => {
  const data = await fetchCreateMemberUploadAssetTokenEndpoint({
    body: {
      memberId,
      mimeType: file.type,
    },
  });
  await fetch(data.url, {
    method: "PUT",
    body: file,
    headers: data.headers,
  });

  await fetchAddBreederUploadedImageEndpoint({
    body: {
      breederId: breederId,
      mimeType: file.type,
      assetId: data.assetId,
      setProfilePicture: profilePicture,
      memberId,
      filename: "",
    },
  });

  return { url: data.url, assetId: data.assetId };
};

export const uploadLitterImage = async (memberId: string, litterId: string, file: File | Blob, profilePicture: boolean) => {
  const data = await fetchCreateMemberUploadAssetTokenEndpoint({
    body: {
      memberId,
      mimeType: file.type,
    },
  });
  await fetch(data.url, {
    method: "PUT",
    body: file,
    headers: data.headers,
  });

  await fetchAddLitterUploadedImageEndpoint({
    body: {
      litterId: litterId,
      mimeType: file.type,
      assetId: data.assetId,
      memberId: memberId,
      filename: "",
      setPedigree: false,
      setProfilePicture: profilePicture,
    },
  });

  return { url: data.url, assetId: data.assetId };
};

export const uploadPuppyImage = async (memberId: string, litterId: string, puppyId: string, file: File | Blob) => {
  const data = await fetchCreateMemberUploadAssetTokenEndpoint({
    body: {
      memberId,
      mimeType: file.type,
    },
  });
  await fetch(data.url, {
    method: "PUT",
    body: file,
    headers: data.headers,
  });

  await fetchAddLitterPuppyImageEndpoint({
    body: {
      litterId: litterId,
      mimeType: file.type,
      assetId: data.assetId,
      memberId: memberId,
      filename: "",
      puppyId: puppyId,
      setProfilePicture: true,
    },
  });

  return { url: data.url, assetId: data.assetId };
};
