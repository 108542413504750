"use client";

import { useUser } from "@clerk/nextjs";
import { InfoOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRouter } from "next/navigation";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { CiImageOn } from "react-icons/ci";
import { revalidateCurrentBreeder, revalidateCurrentMember, revalidateDog } from "../../../app/actions";
import {
  fetchDogTakeOwnershipEndpoint,
  fetchRegisterDogWithoutOwnerEndpoint,
  useAllDogsWithoutOwnerEndpoint,
  useGetPrivateMemberDetailsEndpoint,
  useRegisterDogEndpoint,
} from "../../../components/generated/happydogsComponents";
import { DogView, RegisterDogEndpointCommand } from "../../../components/generated/happydogsSchemas";
import { orange, tan } from "../../../components/Theme";
import { HandleAsyncFunctionWithToast } from "../../../components/Toasts";
import { uploadDogImage } from "../../../components/use-upload-image";
import { ColumnWrapper } from "../../../styles/styledComponents";
import CustomToggleButton from "../../CustomToggleButton";
import dogbreeds from "../../dogbreeds.json" assert { type: "json" };
import { useObjectUrl } from "../../handleObject";
import Uploader from "../../ImageUpload";
import KommuneSelect from "../../KommuneSelect";

const AddDog = ({
  setOpen,
  redirect = true,
  ownDog = true,
  regNr = "",
  defaultBreed,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  redirect?: boolean;
  ownDog?: boolean;
  regNr?: string;
  defaultBreed?: string;
}) => {
  const { user } = useUser();
  const router = useRouter();
  const memberId = (user?.publicMetadata?.member_id ?? null) as string;
  const [newDog, setNewDog] = useState<Partial<RegisterDogEndpointCommand>>({
    isMale: false,
    isAvailableForBreeding: false,
    registryId: regNr,
    breed: defaultBreed,
  });
  const registerDogMutation = useRegisterDogEndpoint();
  const [profilePicture, setProfileImage] = useState<File>();
  const src = useObjectUrl(profilePicture);

  const { data: member } = useGetPrivateMemberDetailsEndpoint({});
  const [uploadImage, setUploadImage] = useState(false);
  const [confirmOwnerShip, setConfirmOwnerShip] = useState(ownDog);
  const { data } = useAllDogsWithoutOwnerEndpoint({});
  const [foundDog, setFoundDog] = useState<DogView>();

  const findDog = (registryId) => {
    const dog = data?.dogs.find((dog) => {
      return dog.registryId.toLowerCase() === registryId?.toLocaleLowerCase();
    });
    if (dog) {
      setFoundDog(dog);
      setNewDog({
        ...dog,
        id: dog.id,
        motherRegistryId: dog.motherId,
        fatherRegistryId: dog.fatherId,
        isMale: dog.isMale,
        breed: dog.breed,
      } as Partial<RegisterDogEndpointCommand>);
    } else {
      if (foundDog) {
        setNewDog({});
        setFoundDog(undefined);
      }
    }
    return dog;
  };

  const handleChange = <T extends keyof RegisterDogEndpointCommand>(key: T, value: RegisterDogEndpointCommand[T]) => {
    setNewDog((prevDog) => ({
      ...prevDog,
      [key]: value,
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.currentTarget.reportValidity();
    const { location } = e.currentTarget;
    if (confirmOwnerShip) {
      if (!!foundDog) {
        claimGhostDog(location.value);
        revalidateDog(foundDog);
      } else {
        registerDog(location.value);
      }
    } else {
      registerGhostDog();
    }
    revalidateCurrentBreeder();
  };

  const claimGhostDog = HandleAsyncFunctionWithToast(async (location: string) => {
    if (foundDog) {
      await fetchDogTakeOwnershipEndpoint({
        body: {
          dogId: foundDog.id,
          memberId: member?.id!,
          location: location,
        },
      });
    }
    setOpen(false);
  });

  const registerGhostDog = HandleAsyncFunctionWithToast(async () => {
    const { dogId } = await fetchRegisterDogWithoutOwnerEndpoint({
      body: {
        registryId: newDog.registryId!,
        registryName: newDog.registryName!,
        motherRegistryId: newDog.motherRegistryId!,
        fatherRegistryId: newDog.fatherRegistryId!,
        isMale: newDog.isMale!,
        breed: newDog.breed!,
        memberId: member?.id!,
      },
    });

    if (profilePicture) {
      await uploadDogImage(member!.id, dogId, profilePicture, true);
    }
    setOpen(false);
  });

  const [showToolTip, setShowTooltip] = useState(false);

  const registerDog = HandleAsyncFunctionWithToast(async (location: string) => {
    const { dogId, dogSlug } = await registerDogMutation.mutateAsync({
      body: {
        ...(newDog as RegisterDogEndpointCommand), //TODO: Validate all fields have been set
        memberId: memberId!,
        aboutMe: "",
        otherResults: [],
        healthResults: [],
        location,
      },
    });

    if (profilePicture) {
      await uploadDogImage(memberId, dogId, profilePicture, true);
    }

    await revalidateCurrentMember();

    setOpen(false);
    if (redirect) router.push(`/dog/${dogSlug}`);
  });

  return (
    <Box width={"100%"} sx={{ backgroundColor: orange[15] }}>
      <Container maxWidth="lg">
        <Box display={"flex"} flexDirection={"column"} gap={{ md: "40px", xs: "20px" }} paddingBottom="15px">
          <Typography variant="h2">Legg til hund</Typography>
          <Box component={"form"} onSubmit={handleSubmit} id="addDog">
            <Box alignSelf={"start"} display={"flex"} mb="5px">
              <FormControlLabel
                onClick={() => setConfirmOwnerShip(!confirmOwnerShip)}
                control={<Checkbox color="primary" checked={confirmOwnerShip} />}
                label="Jeg eier denne hunden"
              />
              <Tooltip
                arrow
                title={
                  "Ved å bekrefte at du eier hunden, vil hunden bli lagt til i din profil og du vil få mulighet til å redigere profilen. Dersom du ikke eier hundden, vil hunden kunne vises men ikke redigeres før eier registrerer seg på HappyDogs."
                }
                open={showToolTip}
                onClose={() => setShowTooltip(false)}
                onMouseOver={() => setShowTooltip(true)}
              >
                <IconButton onClick={() => setShowTooltip(true)}>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: "flex", gap: { md: 5, xs: 3 }, flexDirection: { xs: "column", md: "row" } }}>
              <ColumnWrapper gap={2} pt={confirmOwnerShip ? 2 : 0}>
                <TextField
                  label="Registreringsnummer"
                  value={newDog.registryId ?? ""}
                  onChange={(e) => {
                    findDog(e.target.value);
                    handleChange("registryId", e.target.value);
                  }}
                  required
                  placeholder="NO44444/24"
                  onInput={(e: FormEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLInputElement;

                    const reg = new RegExp(/^\S*$/);

                    if (reg.test(target.value)) {
                      target.setCustomValidity("");
                    } else {
                      target.setCustomValidity("Registreringsnummer skal ikke ha mellomrom");
                    }
                  }}
                />
                <TextField
                  disabled={!!foundDog}
                  label="Registreringsnavn"
                  value={newDog.registryName ?? ""}
                  onChange={(e) => handleChange("registryName", e.target.value)}
                  required
                  placeholder="eks. HappyDogs Bella"
                  onInput={(e: FormEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLInputElement;

                    const reg = new RegExp(/\w{3,}.*/);

                    if (reg.test(target.value)) {
                      target.setCustomValidity("");
                    } else {
                      target.setCustomValidity("Navnet må inneholde minst 3 bokstaver");
                    }
                  }}
                />
                <TextField
                  sx={{ display: confirmOwnerShip ? "flex" : "none" }}
                  value={newDog.titles ?? ""}
                  onChange={(e) => handleChange("titles", e.target.value)}
                  label="Titler"
                />

                <Autocomplete
                  disabled={!!foundDog}
                  disableClearable
                  value={newDog.breed ?? ""}
                  options={dogbreeds.map((breed) => breed.navn)}
                  onChange={(event, newValue) => {
                    handleChange("breed", newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Rase" value={newDog.breed ?? ""} required />}
                />
                <Box style={{ display: confirmOwnerShip ? "flex" : "none" }}>
                  <KommuneSelect required={confirmOwnerShip} name="location" setKommune={() => void 0} value={member?.location} />
                </Box>
                <Box display={confirmOwnerShip ? "none" : "block"}>
                  <CustomToggleButton
                    disabled={!!foundDog}
                    leftValue="Tispe"
                    rightValue="Hannhund"
                    value={newDog.isMale ? "Hannhund" : "Tispe"}
                    setSelectedResult={(e) => handleChange("isMale", e === "Hannhund")}
                    label="Kjønn"
                  />
                </Box>
              </ColumnWrapper>

              <ColumnWrapper gap={2}>
                <Box display={confirmOwnerShip ? "block" : "none"}>
                  <CustomToggleButton
                    disabled={!!foundDog}
                    leftValue="Tispe"
                    rightValue="Hannhund"
                    value={newDog.isMale ? "Hannhund" : "Tispe"}
                    setSelectedResult={(e) => handleChange("isMale", e === "Hannhund")}
                    label="Kjønn"
                  />
                </Box>

                <TextField
                  disabled={!!foundDog}
                  value={newDog.fatherRegistryId ?? ""}
                  onChange={(e) => handleChange("fatherRegistryId", e.target.value)}
                  label="Far (Registreringsnummer)"
                  required
                  placeholder="NO22222/22"
                  onInput={(e: FormEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLInputElement;

                    const reg = new RegExp(/^\S*$/);

                    if (reg.test(target.value)) {
                      target.setCustomValidity("");
                    } else {
                      target.setCustomValidity("Registreringsnummer skal ikke ha mellomrom");
                    }
                  }}
                />
                <TextField
                  disabled={!!foundDog}
                  value={newDog.motherRegistryId ?? ""}
                  onChange={(e) => handleChange("motherRegistryId", e.target.value)}
                  label="Mor (Registreringsnummer)"
                  required
                  placeholder="NO33333/21"
                  onInput={(e: FormEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLInputElement;

                    const reg = new RegExp(/^\S*$/);

                    if (reg.test(target.value)) {
                      target.setCustomValidity("");
                    } else {
                      target.setCustomValidity("Registreringsnummer skal ikke ha mellomrom");
                    }
                  }}
                />
                <ButtonBase
                  disabled={!!foundDog}
                  sx={{
                    height: "80%",
                    border: `2px dotted ${tan[50]}`,
                    maxWidth: "100%",
                    width: profilePicture ? "fit-content" : "100%",
                    borderRadius: "10px",
                  }}
                  onClick={() => setUploadImage(true)}
                >
                  <Box position={"absolute"}>
                    {!profilePicture && <CiImageOn color={tan[300]} fontSize={"30px"} />}
                    <Typography variant="subtitle2">{profilePicture ? "Endre bilde" : "Last opp bilde"}</Typography>
                  </Box>
                  <Box height={"120px"} maxWidth={"100%"}>
                    <img
                      style={{ display: "flex", width: "100%", height: "120px", objectFit: "cover", borderRadius: "10px", opacity: 0.4 }}
                      alt=""
                      src={src}
                    />
                  </Box>
                </ButtonBase>
              </ColumnWrapper>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} marginTop={"40px"}>
              <Button onClick={() => setOpen(false)} type="reset" variant="outlined" color="secondary">
                Avbryt
              </Button>
              <Button variant="contained" type="submit">
                Legg til hund
              </Button>
            </Box>
          </Box>{" "}
          <Uploader
            id="addDog"
            onUpload={(file: File, _ = false) => Promise.resolve(setProfileImage(file))}
            uploadImage={uploadImage}
            handleClose={() => setUploadImage(false)}
            setProfilePicture={true}
            closeAfterUpload={true}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default AddDog;
